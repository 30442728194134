import Image from 'next/image'
import { useState } from 'react'
import logo from '../../public/images/logo.svg'
import discord from '../../public/images/discord.svg'
import twitter from '../../public/images/twitter.svg'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'



export default function Header(props){
  const router = useRouter()
  const defaultOnClick = () => {
    router.push('/')
  }

  const { 
    scrollToMint = defaultOnClick,
    scrollToAbout = defaultOnClick,
    scrollToRoadmap = defaultOnClick,
    scrollToTeam = defaultOnClick 
  } = props
  const { currentAccount, getWalletProvider } = useWalletContext()

  const [showMenu, setShowMenu] = useState(false)

  const onClick = (fn) => {
    return () => {
      setShowMenu(false)
      fn()
    }
  }

  return (
    <header className="top-0 w-full overflow-hidden z-40">
      {
        showMenu ? (
          <div className="h-screen w-screen bg-purple fixed inset-0 p-6 flex flex-col">
            <div className="flex">
              <nav className="z-50 w-full h-full text-white items-center text-center">
                <div className='items-center text-xs justify-center cursor-pointer col-span-2 flex  mb-10' onClick={onClick(scrollToMint)}>
                  <Image src={logo} alt='logo' height={120} priority/>
                </div>
                <div className='items-center justify-center cursor-pointer my-4' onClick={onClick(scrollToAbout)}>About</div>
                <div className='items-center justify-center cursor-pointer my-4' onClick={onClick(scrollToRoadmap)}>Roadmap</div>
                <div className='items-center justify-center cursor-pointer my-4' onClick={onClick(scrollToTeam)}>Team</div>
                {currentAccount ? (
                  <div className='items-center text-xs justify-center text-center my-4'>{truncateWallet(currentAccount)}</div>
                ) : (
                  <div className='items-center text-xs justify-center cursor-pointer text-center my-4' onClick={getWalletProvider}>Connect  Wallet</div>
                )}
              </nav>
              <div className="flex: md:hidden mt-4" onClick={() => setShowMenu(!showMenu)}>
                <XIcon className="w-10" />
              </div>
            </div>
            <div className="flex">
              <div className='items-center justify-center cursor-pointer my-4 mr-2'>
                <a href='https://twitter.com/metabellanfts' target='_blank' rel="noreferrer">
                  <Image src={twitter} alt='logo' />
                </a>
              </div>

              <div className='items-center text-xs justify-center my-4 ml-2'>
                <a href='https://discord.gg/pokmihq' target='_blank' rel="noreferrer">
                  <Image src={discord} alt='logo'  />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <nav className="h-40 z-40 w-full grid md:grid-cols-8 text-white items-center max-w-screen-xl mx-auto">
              <div className='items-center text-xs justify-center cursor-pointer hidden md:flex' onClick={scrollToAbout}>About</div>
              <div className='items-center text-xs justify-center cursor-pointer hidden md:flex' onClick={scrollToRoadmap}>Roadmap</div>
              <div className='items-center text-xs justify-center cursor-pointer hidden md:flex'>
                <a href='https://twitter.com/metabellanfts' target='_blank' rel="noreferrer">
                  <Image src={twitter} alt='logo' height={120} />
                </a>
              </div>
              <div className='items-center text-xs justify-center cursor-pointer col-span-2 flex' onClick={scrollToMint}>
                <Image src={logo} alt='logo' height={120} priority/></div>
              <div className='items-center text-xs justify-center hidden md:flex'>
                <a href='https://discord.gg/pokmihq' target='_blank' rel="noreferrer">
                  <Image src={discord} alt='logo' height={120} />
                </a>
              </div>
              <div className='items-center text-xs justify-center cursor-pointer hidden md:flex' onClick={scrollToTeam}>Team</div>
              {currentAccount ? (
                <div className='items-center text-xs justify-center text-center hidden md:flex'>{truncateWallet(currentAccount)}</div>
              ) : (
                <div className='items-center text-xs justify-center cursor-pointer text-center hidden md:flex' onClick={getWalletProvider}>Connect<br/>Wallet</div>
              )}
            </nav>
            <div className="flex: md:hidden mr-4" onClick={() => setShowMenu(!showMenu)}>
              <MenuIcon className="w-10" />
            </div>
          </div>
        )
      }
    </header>
  )
}