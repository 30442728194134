import { useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import * as Sentry from '@sentry/nextjs'
import Countdown from 'react-countdown';


import mintLogo from '../../public/images/mint.svg'
import abella1 from '../../public/images/abella/1.png'
import abella2 from '../../public/images/abella/2.png'
import abella3 from '../../public/images/abella/3.png'
import abella4 from '../../public/images/abella/4.png'
import abella5 from '../../public/images/abella/5.png'
import abella6 from '../../public/images/abella/6.png'
import abella7 from '../../public/images/abella/7.png'
import abella8 from '../../public/images/abella/8.png'
import abella9 from '../../public/images/abella/9.png'
import abella10 from '../../public/images/abella/10.png'
import abella11 from '../../public/images/abella/11.png'
import abella12 from '../../public/images/abella/12.png'
import abella13 from '../../public/images/abella/13.png'
import abella14 from '../../public/images/abella/14.png'
import abella15 from '../../public/images/abella/15.png'
import discord from '../../public/images/discord.svg'
import twitter from '../../public/images/twitter.svg'

import Card from './Card'
import CardCaroussel from './CardCaroussel'

import AbellaCards from '../utils/cards.json'
import { useWalletContext } from '../utils/wallet'
import { toast } from './base/Toaster'
import { useModalContext } from '../utils/modal'
import { CheckIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/outline'

declare global {
  interface Window {
      dataLayer:any;
  }
}

export default function MintSection(props){
  const {anchor, className, ...rest} = props

  const { mint, metabellaPrice, totalSupply, maxSupply, maxMintPerTx, callContractData, isPublicSale, isPreSale, ownedNfts, isWhitelisted,...all } = useWalletContext()
  const { openModal, closeModal, modalProps } = useModalContext()


  
  const [wantedMetabella, setWantedMetabella] = useState(1)
  const [email, setEmail] = useState('')
  const [requestStatus, setRequestStatus] = useState(null)
  const isSaleOpen = isPreSale || isPublicSale

  const onMint = async () => {
    try {
      const minting = await mint(wantedMetabella)

      if (minting?.tx?.hash) {
        toast.success(`Metabella item successfully minted`)
          
        if (typeof window === undefined) return
        window.dataLayer?.push({ event: 'mint', eventProps: {result: 'success', number: wantedMetabella} })
      } else {
        window.dataLayer?.push({ event: 'mint', eventProps: {result: 'fail'} })
      }

      await callContractData()
      
    } catch (error) {
      toast.error(`Fail to mint Metabella: ${error.message}`)
      if (typeof window === undefined) return
      window.dataLayer?.push({ event: 'mint', eventProps: {result: 'fail'} })
      Sentry.captureException(error)
    }
  }

  const addEmailToNewsletter = async () => {
    const req = fetch('https://api.prod.pokmi.com/newsletter', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, campaign: 'metabella'})
    });
    setRequestStatus('pending')
    const res: any = await req.catch(() => setRequestStatus(null))
    setRequestStatus(res?.ok ? 'success' : null )
  }

  const canSubmit = email && requestStatus !== 'pending'

  const openComingSoonModal = async () => {
    openModal({
      content: (
        <div className='mt-16 w-full flex flex-col items-center'>
          <h2 className='hyper text-xl md:text-3xl uppercase mb-4'>Comming Soon !</h2>
          <div className='flex gap-4'>
            <div className='items-center text-xs justify-center cursor-pointer flex'>
              <a href='https://twitter.com/metabellanfts' target='_blank' rel="noreferrer">
                <Image src={twitter} alt='logo' height={120} />
              </a>
            </div>
            <div className='items-center text-xs justify-center  flex'>
              <a href='https://discord.gg/pokmihq' target='_blank' rel="noreferrer">
                <Image src={discord} alt='logo' height={120} />
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  const isAllowedToMint = metabellaPrice && wantedMetabella && (isPublicSale || (isPreSale && isWhitelisted))

  return (
    <section ref={anchor} className={clsx(className, 'w-full flex flex-col text-center')} {...rest}>
      <h2 className={clsx(isSaleOpen || 'hidden', 'hyper text-lg md:text-2xl text-[#ffeb3b]')}>! {isPreSale ? 'PRESALE' : 'PUBLIC SALE'} IS LIVE !</h2>
      <h2 className={clsx(isPreSale || 'hidden', 'hyper text-md md:text-lg ')}>
      Public sale opens in <Countdown daysInHours date={new Date('2022-04-20T22:00:00Z')} />,
  
      </h2>
      <a href='https://opensea.io/collection/metabella-by-pokmi' target={'_blank'} className={clsx(isSaleOpen || 'hidden', 'hyper text-sm md:text-lg cursor-pointer')} rel="noreferrer">
        View the collection on <span className='underline'>Opensea</span>
      </a>
      <div className='w-full flex items-center justify-center flex-1 m-auto'>
        <CardCaroussel>
          <Card image={abella1}/>
          <Card image={abella2}/>
          <Card image={abella3}/>
          <Card image={abella4}/>
          <Card image={abella5}/>
          <Card image={abella6}/>
          <Card image={abella7}/>
          <Card image={abella8}/>
          <Card image={abella9}/>
          <Card image={abella10}/>
          <Card image={abella11}/>
          <Card image={abella12}/>
          <Card image={abella13}/>
          <Card image={abella14}/>
          <Card image={abella15}/>
        </CardCaroussel>
        
      </div>
      <div className={clsx('text-xl text-[#ffeb3b] font-semibold', (isWhitelisted && !isPublicSale) ? 'block' : 'hidden')} >
        You are Whitelisted
      </div>
      <div className={clsx("text-center px-4  flex-col items-center", isSaleOpen ? 'flex' : 'hidden')}>
        <p>{`Total Metabella Minted: ${totalSupply} / 1158`} {!!ownedNfts && <>( You own {ownedNfts} )</> }</p>
        <p>{`Price per Metabella: ${metabellaPrice} ETH`}</p>
        <div className='mb-2 flex mx-auto items-center'>
          {`Wanted Metabella ${isPreSale ? `(max. ${maxMintPerTx} per address)` : ''}`}
          <MinusCircleIcon className='ml-2 w-6 h-6' onClick={() => setWantedMetabella(Math.max(wantedMetabella - 1, 1))}/>
          <input className="p-1 w-12 text-bold text-center h-8 outline-none rounded bg-[#90a3eb]" type={'number'} value={wantedMetabella} onChange={(e) => setWantedMetabella(parseFloat(e.target.value))} min={0} max={10000}/>
          <PlusCircleIcon className='w-6 h-6' onClick={() => setWantedMetabella(Math.min(wantedMetabella + 1, maxMintPerTx))}/>
        </div>
      </div>
      <div className={
        clsx(`justify-center mt-auto mt-4 mb-8 transition ${metabellaPrice && wantedMetabella ? 'hover:scale-110' : ''}`, isSaleOpen ? 'flex' : 'hidden')
      }>
        <Image src={mintLogo} alt='logo' height={90} className={`${metabellaPrice && wantedMetabella ? 'cursor-pointer' : 'cursor-not-allowed filter brightness-75'}`} onClick={metabellaPrice && wantedMetabella ? onMint : openComingSoonModal}/>
      </div>
      <div className={
        clsx(`flex-col justify-center mt-auto mb-8 transition items-center`, isSaleOpen ? 'hidden' : 'flex')
      }>
        <h2 className=' hyper text-3xl'>Mint is closed</h2>
        <div className=' flex flex-col md:flex-row gap-2 items-center max-w-full'>
          <a  className='hyper text-3xl underline' href="https://opensea.io/collection/metabella-by-pokmi" target={'_blank'} rel="noreferrer">See collection on OpenSea</a>
        {/* <p>Add your email to be updated :</p>
        <input 
          placeholder='abella@pokmi.com'
          className="ml-2 p-1 w-72 text-sm text-bold text-center h-8 outline-none rounded bg-[#90a3eb] placeholder-white placeholder-opacity-75 focus:placeholder-opacity-0" value={email} onChange={(e) => {setEmail(e.target.value); setRequestStatus(null)}} />
          {requestStatus !== 'success' ? <button disabled={!canSubmit} className={clsx('bg-white hyper text-blue px-1 rounded mx-2', canSubmit || 'brightness-75 cursor-not-allowed')} onClick={addEmailToNewsletter}>GO !</button>
          :<span className='h-8 w-8 px-11'><CheckIcon className='h-full'/></span>} */}
        </div>
      </div>
    </section>
  )
}
